import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'
// 引入Antd
import { Layout } from 'antd'
const { Header, Footer, Content } = Layout
import styles from './MainLayout.module.scss'
import Logo from '../components/Logo/Logo'
import UserInfo from '../components/UserInfo/UserInfo'
import useLoadUserData from '../hooks/useLoadUserData'
import useNavPage from '../hooks/useNavPage'

const MainLayout: FC = () => {
  // const { waitingUserDate } = useLoadUserData()
  // useNavPage(waitingUserDate)

  return (
    <Layout>
      {/* 头部 */}
      <Header className={styles.header}>
        <div className={styles.left}>
          {/* 登录组件 */}
          <Logo />
        </div>
        <div className={styles.right}>
          {/* 用户信息组件 */}
          <UserInfo />
        </div>
      </Header>

      {/* 内容 */}
      <Content className={styles.main}>
        <Outlet />
      </Content>

      {/* 底部 */}
      <Footer className={styles.footer}>星问卷 &copy;2024 - present.Created by LKK</Footer>
    </Layout>
  )
}

export default MainLayout
