import React, { FC, useState } from 'react'
import styles from './common.module.scss'
import { useRequest } from 'ahooks'
import { Typography, Empty, Spin, Tag, Table, Space, Button, Modal, message } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import ListSearch from '../../components/Search/ListSearch'
const { confirm } = Modal

const { Title } = Typography

import useLoadQuestionListData from '../../hooks/useLoadQuestionListData'
import ListPage from '../../components/Listpage/ListPage'
import { formatTime } from '../../utils/day'
import { deletedQuestionService, updateQuestionService } from '../../services/question'

// table的行
// const rawQuestionList = [
//   {
//     _id: 'q1', // mongodb 数据库 _id
//     title: '问卷1',
//     isPublished: false,
//     isStar: true,
//     answerCount: 5,
//     createdAt: '3月10日 10:30',
//   },
// ]

type rawType = {
  _id: string
  fe_id: string
  title: string
  isPublished: boolean
  isStar: boolean
  isDeleted: boolean
  answerCount: number
  createAt: Date
}

// table的列
const columns = [
  {
    title: '标题',
    dataIndex: 'title',
    // key: 'title', // 循环列的key，它默认取dataIndex 的值
  },
  {
    title: '是否发布',
    dataIndex: 'isPublished',
    render: (isPublished: boolean) => {
      return isPublished ? <Tag color="#87d068">已发布</Tag> : <Tag>未发布</Tag>
    },
  },
  {
    title: '答卷',
    dataIndex: 'answerCount',
  },
  {
    title: '创建时间',
    dataIndex: 'createAt',
    render: (createAt: Date) => {
      return formatTime(createAt)
    },
  },
]

const Trash: FC = () => {
  // 获取问卷列表数据
  const { data = {}, loading, refresh } = useLoadQuestionListData({ isDeleted: true })
  const { questionList = [], total = 0 } = data
  // console.log(questionList, total)

  // 选择框类型
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox')
  // 收集选中的fe_id数组
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  // 恢复
  const { loading: recoverLoading, run: recover } = useRequest(
    async () => {
      for await (const id of selectedIds) {
        await updateQuestionService(id, {
          isDeleted: false,
        })
      }
    },
    {
      manual: true,
      onSuccess() {
        message.success('恢复成功')
        refresh() // 手动刷新列表
        setSelectedIds([]) // 清空id数组
      },
    }
  )

  // 删除
  const { loading: deletedLoading, run: deleteQuestion } = useRequest(
    async () => await deletedQuestionService(selectedIds),
    {
      manual: true,
      onSuccess() {
        message.success('彻底删除成功')
        refresh() // 手动刷新列表
        setSelectedIds([]) // 清空id数组
      },
    }
  )

  function del() {
    confirm({
      title: '是否确定彻底删除问卷？',
      icon: <ExclamationCircleOutlined />,
      content: '删除后不可找回',
      onOk: deleteQuestion,
      okText: '确定',
      cancelText: '取消',
    })
  }

  return (
    <>
      {/* 头部 */}
      <div className={styles.header}>
        <div className={styles.left}>
          <Title level={3}>回收站</Title>
          <div style={{ marginBottom: '5px' }}>
            <Space>
              <Button type="primary" disabled={selectedIds.length === 0} onClick={recover}>
                {selectedIds.length <= 1 ? '恢复' : '批量恢复'}
              </Button>
              <Button danger disabled={selectedIds.length === 0} onClick={del}>
                {selectedIds.length <= 1 ? '彻底删除' : '批量彻底删除'}
              </Button>
            </Space>
          </div>
        </div>
        <div className={styles.right}>
          {/* 搜索 */}
          <ListSearch />
        </div>
      </div>

      {/* 内容 */}
      <div className={styles.content_trash}>
        {/* 加载中... */}
        {loading && (
          <div style={{ textAlign: 'center' }}>
            <Spin />
          </div>
        )}

        {/* 回收站表格 */}
        {!loading && questionList.length > 0 && (
          <>
            <Table
              dataSource={questionList}
              columns={columns}
              pagination={false}
              rowKey={(record: rawType) => record.fe_id}
              scroll={{ y: 500 }}
              rowSelection={{
                type: selectionType,
                onChange: (selectedRowKeys, selectedRows) => {
                  console.log(selectedRowKeys, selectedRows)
                  setSelectedIds(selectedRowKeys as string[])
                },
              }}
            />
          </>
        )}

        {/* Empty空状态 */}
        {!loading && questionList.length === 0 && <Empty description="暂无数据" />}
      </div>

      {/* 底部 */}
      <div className={styles.footer}>
        <ListPage total={total} />
      </div>
    </>
  )
}

export default Trash
