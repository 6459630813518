import { useRequest } from 'ahooks'
import React, { FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getUserInfoService } from '../../services/user'
import { UserOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import { getToken, removeToken } from '../../utils/token'
// 引入获取redux中user用户信息的hook
import useGetUserInfo from '../../hooks/useGetUserInfo'
import { useDispatch } from 'react-redux'
// 引入actions
import { logoutReducer } from '../../store/userReducer'

const UserInfo: FC = () => {
  const navigate = useNavigate()
  // 获取用户信息
  // const { data, error } = useRequest(getUserInfoService)
  // const { username, nickname } = data || {}

  // 获取redux用户信息
  const { username, nickname, token } = useGetUserInfo()
  const dispatch = useDispatch()

  // 退出登录
  function logout() {
    // 清空store中user用户信息数据
    dispatch(logoutReducer())
    // 清除本地token
    removeToken()
    message.success('退出成功')
    // 导航到登录页
    navigate('/login')
  }

  const UserInfo = (
    <>
      <span style={{ color: '#e8e8e8', margin: '0 10px' }}>
        <UserOutlined style={{ fontSize: 20 }} />
        {nickname}
      </span>
      <Button onClick={logout} size="small">
        退出登录
      </Button>
    </>
  )

  const Login = (
    <>
      <Link to="/login">登录</Link>
    </>
  )
  return <>{token ? UserInfo : Login}</>
}

export default UserInfo
