import React, { FC, useState } from 'react'
import styles from './QuestionCard.module.scss'
import { Space, Button, Divider, Tag, Popconfirm, Modal, message, Typography } from 'antd'
import {
  EditOutlined,
  LineChartOutlined,
  StarOutlined,
  StarFilled,
  CopyOutlined,
  DeleteOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons'
import { useNavigate, Link } from 'react-router-dom'
const { confirm } = Modal

import { formatTime } from '../../utils/day'
import { useRequest } from 'ahooks'
import { duplicateQuestionService, updateQuestionService } from '../../services/question'

// props类型
type PropsType = {
  user_id: string
  _id: string
  fe_id: string
  title: string
  isPublished: boolean
  isStar: boolean
  answerCount: number
  createAt: string
  refresh: () => void
}

const QuestionCard: FC<PropsType> = (props: PropsType) => {
  const { user_id, fe_id, title, isPublished, isStar, answerCount, createAt, refresh } = props

  const navigate = useNavigate()
  // 修改标星
  const [isStarState, setIsStarState] = useState(isStar)
  // 利用ahooks中的useRequest发送请求修改标星
  const { loading: changeStarLoading, run: changeStar } = useRequest(
    async () => {
      const result = await updateQuestionService(fe_id, {
        isStar: !isStarState,
      })
      return result
    },
    {
      manual: true,
      onSuccess(result) {
        console.log(result)
        setIsStarState(!isStarState) // 更新isStar
        message.success(result.msg)
      },
    }
  )

  // 复制
  const { loading: duplicateLoading, run: duplicate } = useRequest(
    // async () => {
    //   const res = await duplicateQuestionService(_id)
    //   // 这里需要返回请求结果
    //   return res
    // }
    // 或者：直接这样写
    async () => await duplicateQuestionService(fe_id),
    {
      manual: true,
      // 在这里才能拿到请求的数据
      onSuccess(result: any, data) {
        console.log(result, data)
        message.success(result.msg)
        navigate(`/question/edit/${result.fe_id}`) // 跳转到问卷编辑页
      },
    }
  )

  // 删除
  const { loading: delLoading, run: deleteQuestion } = useRequest(
    async () =>
      await updateQuestionService(fe_id, {
        isDeleted: true,
      }),
    {
      manual: true,
      onSuccess() {
        message.success('删除成功')
        refresh()
      },
    }
  )
  function del() {
    confirm({
      title: '确定删除该问卷？',
      icon: <DeleteOutlined />,
      okText: '确定',
      cancelText: '取消',
      onOk: deleteQuestion,
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {/* 左侧：问卷名称 */}
        <div className={styles.left}>
          <Link to={isPublished ? `/question/stat/${fe_id}` : `/question/edit/${fe_id}`}>
            {/* 间隔组件 */}
            <Space>{isStarState && <StarFilled style={{ color: '#ffb806' }} />}</Space>
            {title}
          </Link>
        </div>
        {/* 右侧：发布情况 、时间 */}
        <div className={styles.right}>
          {/* 间隔组件 */}
          <Space>
            {isPublished ? (
              <span style={{ color: 'green' }}>
                <Tag color="#87d068">已发布</Tag>
              </span>
            ) : (
              <Tag>未发布</Tag>
            )}
            <span>答卷: {answerCount}</span>
            <span
              style={{
                background: 'rgb(240 242 255)',
                padding: '2px 4px',
                borderRadius: '7px',
              }}
            >
              <FieldTimeOutlined />
              {formatTime(createAt)}
            </span>
          </Space>
        </div>
      </div>

      {/* 分割线 */}
      <Divider style={{ margin: '12px' }} />

      <div className={styles['button-container']}>
        <div className={styles.left}>
          <Space>
            <Button
              type="text"
              size="small"
              icon={<EditOutlined />}
              onClick={() => navigate(`/question/edit/${fe_id}`)}
            >
              编辑问卷
            </Button>
            <Button
              type="text"
              size="small"
              icon={<LineChartOutlined />}
              onClick={() => navigate(`/question/stat/${fe_id}`)}
            >
              数据统计
            </Button>
          </Space>
        </div>
        <div className={styles.right}>
          <Space>
            <Button
              type="text"
              size="small"
              icon={<StarOutlined />}
              onClick={changeStar}
              disabled={changeStarLoading} // 防止频繁点击
            >
              {isStarState ? '取消标星' : '标星'}
            </Button>
            <Popconfirm
              placement="top"
              title="确定复制该问卷？"
              okText="确定"
              cancelText="取消"
              onConfirm={duplicate}
            >
              <Button type="text" size="small" icon={<CopyOutlined />} disabled={duplicateLoading}>
                复制
              </Button>
            </Popconfirm>

            <Button
              type="text"
              size="small"
              icon={<DeleteOutlined />}
              onClick={del}
              disabled={delLoading}
            >
              删除
            </Button>
          </Space>
        </div>
      </div>
    </div>
  )
}

export default QuestionCard
