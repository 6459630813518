import { useEffect } from 'react'
import useGetUserInfo from './useGetUserInfo'
import { useLocation, useNavigate } from 'react-router-dom'
import { isLoginOrRegister, isNoNeeUserInfo } from '../router'

function useNavPage(waitingUserData: boolean) {
  // 获取到redux 中的用户信息
  const { username, token } = useGetUserInfo()
  // 获取到路由页面路径
  const { pathname } = useLocation()
  // 跳转
  const navigate = useNavigate()

  useEffect(() => {
    if (waitingUserData) return

    // 已经登录
    if (token) {
      // console.log('###', token)
      if (isLoginOrRegister(pathname)) {
        navigate('/manage/list')
      }
      return
    }

    // 未登录
    if (isNoNeeUserInfo(pathname)) {
      return navigate(pathname)
    } else {
      navigate('/login')
    }
  }, [waitingUserData, token, pathname])
}

export default useNavPage
