import { configureStore } from '@reduxjs/toolkit'
// 导入user用户信息 和 ts类型
import userReducer, { UserStateType } from './userReducer'
// 导入compoent组件列表 和 ts类型
import componentsReducer, { ComponentStateType } from './componentsReducer'
// 导入pageInfo页面设置 和 ts类型
import pageInfoReducer, { PageInfoStateType } from './pageInfoReducer'
// 持久化
// import storage from 'redux-persist/lib/storage'
// import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistReducer } from 'redux-persist'
// import persistStore from 'redux-persist/es/persistStore'

// 引入撤销重做 redux-undo 库
import undoable, { excludeAction, StateWithHistory } from 'redux-undo'

// 暴露模块类型
export type StateType = {
  user: UserStateType
  components: StateWithHistory<ComponentStateType>
  pageInfo: PageInfoStateType
}

// 创建持久化user的Reducer
// const persistConfig = {
//   key: 'userInfo',
//   storage: storage,
// }
// const persistUserReducer = persistReducer(persistConfig, userReducer)

// 创建一个可撤销的componentList的Reducer
const undoableReducer = undoable(componentsReducer, {
  // 限制只能撤销 20 步
  limit: 20,
  // 屏蔽某些 action ，不进行 undo redo
  filter: excludeAction([
    'todoList/resetComponents', // 重置所有组件
    'todoList/changeSelectedId', // 修改selectedId
    'todoList/selectPrevCompoent', // 选中上一个组件
    'todoList/selectNextCompoent', // 选中下一个组件
  ]),
})
// 创建持久化componentList的Reducer
// const persistComponentListConfig = {
//   key: 'compoentList',
//   storage: storage,
// }
// const persistcompoentsReducer = persistReducer(persistComponentListConfig, undoableReducer)

// 创建持久化pageInfo的Reducer
// const persistPageInfoConfig = {
//   key: 'pageInfo',
//   storage: storage,
// }
// const persistPageInfoReducer = persistReducer(persistPageInfoConfig, pageInfoReducer)

export const store = configureStore({
  reducer: {
    // 用户信息模块
    // user: persistUserReducer, // 使用持久化用户信息的reducer
    user: userReducer,
    // 没有 undo
    // components: persistcompoentsReducer, // 使用持久化组件列表的reducer
    // 增加了undo
    components: undoableReducer,
    pageInfo: pageInfoReducer, // 使用持久化页面设置的reducer
  },
  // middleware: getDefaultMiddleware =>
  //   getDefaultMiddleware({
  //     serializableCheck: {
  //       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  //     },
  //   }),
})

// export const persistor = persistStore(store)
