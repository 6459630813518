import React, { FC, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import {
  PlusOutlined,
  UnorderedListOutlined,
  StarOutlined,
  DeleteOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons'
import styles from './ManageLayout.module.scss'
// 引入编程式导航
import { useNavigate, useLocation } from 'react-router-dom'
import useLoadUserData from '../hooks/useLoadUserData'

// 引入antd组件
import { Button, Layout, Menu, Space, Spin, message, theme } from 'antd'
const { Header, Content, Sider } = Layout

// 接口
import { createQuestionService } from '../services/question'
import { nanoid } from 'nanoid'
import { useRequest } from 'ahooks'
import useNavPage from '../hooks/useNavPage'
import useGetUserInfo from '../hooks/useGetUserInfo'

const ManageLayout: FC = () => {
  // 存储用户信息到redux
  const { waitingUserDate } = useLoadUserData()
  // 路由守卫
  useNavPage(waitingUserDate)
  const { user_id } = useGetUserInfo()

  // 路由
  const naviagte = useNavigate()
  // 获取路由路径
  const { pathname } = useLocation()
  const [collapsed, setCollapsed] = useState(false)
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken()

  // 跳转
  function handleSelect(opt: any) {
    const { key } = opt
    naviagte(key)
  }

  // 利用ahooks发送请求，新建问卷
  const { loading, run: handleCreateClick } = useRequest(
    async () => {
      // 生成nanoid
      const fe_id = nanoid(20)
      const result = await createQuestionService({ fe_id, user_id })
      return result
    },
    {
      manual: true,
      onSuccess(result) {
        const { id } = result || {}
        naviagte(`/question/edit/${id}`)
        message.success('创建成功！')
      },
    }
  )

  return (
    // <>
    //   {!waitingUserDate ? (
    //     <Spin style={{ textAlign: 'center', display: 'block', marginTop: '50px' }} />
    //   ) : (
    //     <div className={styles.container}>
    //       <Layout>
    //         {/* 左侧 */}
    //         <Sider trigger={null} collapsible collapsed={collapsed}>
    //           <div className="demo-logo-vertical" />
    //           <Menu
    //             theme="dark"
    //             mode="inline"
    //             selectedKeys={[pathname]}
    //             items={[
    //               {
    //                 key: '/manage/list',
    //                 icon: <UnorderedListOutlined />,
    //                 label: '我的问卷',
    //               },
    //               {
    //                 key: '/manage/star',
    //                 icon: <StarOutlined />,
    //                 label: '星标问卷',
    //               },
    //               {
    //                 key: '/manage/trash',
    //                 icon: <DeleteOutlined />,
    //                 label: '回收站',
    //               },
    //             ]}
    //             onSelect={handleSelect}
    //           />
    //         </Sider>
    //         <Layout>
    //           <Header style={{ padding: 0, background: colorBgContainer }}>
    //             <Space>
    //               <Button
    //                 type="text"
    //                 icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
    //                 onClick={() => setCollapsed(!collapsed)}
    //                 style={{
    //                   fontSize: '16px',
    //                   width: 64,
    //                   height: 64,
    //                 }}
    //               />
    //               <Button
    //                 type="primary"
    //                 size="large"
    //                 icon={<PlusOutlined />}
    //                 onClick={handleCreateClick}
    //                 disabled={loading}
    //               >
    //                 新建问卷
    //               </Button>
    //             </Space>
    //           </Header>
    //           <Content
    //             style={{
    //               marginTop: '10px',
    //               marginLeft: '10px',
    //               padding: 24,
    //               minHeight: `calc(100vh - 64px - 68px - 48px - 48px)`,
    //               background: colorBgContainer,
    //               borderRadius: borderRadiusLG,
    //             }}
    //           >
    //             {/* 右侧 */}
    //             <Outlet />
    //           </Content>
    //         </Layout>
    //       </Layout>
    //     </div>
    //   )}
    // </>
    <>
      <div className={styles.container}>
        <Layout>
          {/* 左侧 */}
          <Sider trigger={null} collapsible collapsed={collapsed}>
            <div className="demo-logo-vertical" />
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[pathname]}
              items={[
                {
                  key: '/manage/list',
                  icon: <UnorderedListOutlined />,
                  label: '我的问卷',
                },
                {
                  key: '/manage/star',
                  icon: <StarOutlined />,
                  label: '星标问卷',
                },
                {
                  key: '/manage/trash',
                  icon: <DeleteOutlined />,
                  label: '回收站',
                },
              ]}
              onSelect={handleSelect}
            />
          </Sider>
          <Layout>
            <Header style={{ padding: 0, background: colorBgContainer }}>
              <Space>
                <Button
                  type="text"
                  icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    fontSize: '16px',
                    width: 64,
                    height: 64,
                  }}
                />
                <Button
                  type="primary"
                  size="large"
                  icon={<PlusOutlined />}
                  onClick={handleCreateClick}
                  disabled={loading}
                >
                  新建问卷
                </Button>
              </Space>
            </Header>
            <Content
              style={{
                marginTop: '10px',
                marginLeft: '10px',
                padding: 24,
                minHeight: `calc(100vh - 64px - 68px - 48px - 48px)`,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              {/* 右侧 */}
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </div>
    </>
  )
}

export default ManageLayout
