import React, { FC, useEffect, useState } from 'react'
import styles from './common.module.scss'
import { Typography, Empty, Spin } from 'antd'

import QuestionCard from '../../components/QuestionCard/QuestionCard'
import ListSearch from '../../components/Search/ListSearch'
import useLoadQuestionListData from '../../hooks/useLoadQuestionListData'
import ListPage from '../../components/Listpage/ListPage'

const { Title } = Typography

// 问卷列表数据
// const rawQuestionList = [
//   {
//     _id: 'q1', // mongodb 数据库 _id
//     title: '问卷1',
//     isPublished: false,
//     isStar: true,
//     answerCount: 5,
//     createdAt: '3月10日 10:30',
//   },
// ]

const Star: FC = () => {
  // 获取问卷列表数据
  const { data = {}, loading, refresh } = useLoadQuestionListData({ isStar: true })
  const { questionList = [], total } = data

  // 删除问卷后，刷新列表
  function handleRefresh() {
    refresh()
  }

  return (
    <>
      {/* 头部 */}
      <div className={styles.header}>
        <div className={styles.left}>
          <Title level={3}>星标问卷</Title>
        </div>
        <div className={styles.right}>
          {/* 搜索 */}
          <ListSearch />
        </div>
      </div>

      {/* 内容 */}
      <div className={styles.content_star}>
        <div className={styles.list_scroll}>
          {/* 加载中... */}
          {loading && (
            <div style={{ textAlign: 'center' }}>
              <Spin />
            </div>
          )}

          {/* 星标问卷列表 */}
          {!loading &&
            questionList.length > 0 &&
            questionList.map((q: any) => {
              const { _id } = q
              return <QuestionCard key={_id} {...q} refresh={handleRefresh} />
            })}

          {/* Empty空状态 */}
          {!loading && questionList.length === 0 && <Empty description="暂无数据" />}
        </div>
      </div>

      {/* 底部 */}
      <div className={styles.footer}>
        <ListPage total={total} />
      </div>
    </>
  )
}

export default Star
