import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './Logo.module.scss'
// 组件
import { Space, Typography } from 'antd'
const { Title } = Typography
// 图标
import { FormOutlined } from '@ant-design/icons'
// 引入获取redux中user用户信息的hook
import useGetUserInfo from '../../hooks/useGetUserInfo'

const Logo: FC = () => {
  // 获取store中的user用户信息
  const { username, token } = useGetUserInfo()
  const [pathname, setPathname] = useState('/')

  useEffect(() => {
    if (token) {
      // 跳转到 我的问卷
      setPathname('/manage/list')
    } else {
      setPathname('/')
    }
  }, [token])

  return (
    <div className={styles.container}>
      <Link to={pathname}>
        <Space>
          <Title>
            <FormOutlined />
          </Title>
          <Title>星问卷</Title>
        </Space>
      </Link>
    </div>
  )
}

export default Logo
