import { useRequest } from 'ahooks'
import { useEffect, useState } from 'react'
import { getUserInfoService } from '../services/user'
import { useDispatch } from 'react-redux'
import { loginReducer } from '../store/userReducer'
import useGetUserInfo from './useGetUserInfo'
import { getToken } from '../utils/token'

/**
 * @description 获取用户信息存储到store的user
 * @author lkk
 */
function useLoadUserData() {
  // ajax加载完用户信息之后，放在redux中，不用返回
  const [waitingUserDate, setWaitingUserDate] = useState(true)
  const dispatch = useDispatch()
  // 获取到本地的token
  const token = getToken()

  // 请求获取用户信息
  const { run } = useRequest(getUserInfoService, {
    manual: true,
    onSuccess(result) {
      const { username, nickname, _id } = result
      // console.log('@@@@', result)
      // 存储用户信息到redux
      dispatch(loginReducer({ username, nickname, token, user_id: _id }))
    },
    onError(error) {
      console.log(error)
    },
    onFinally() {
      setWaitingUserDate(false)
    },
  })

  // 判断reudx 是否已经存在用户信息
  const { username } = useGetUserInfo()

  useEffect(() => {
    if (username) {
      setWaitingUserDate(false)
      return
    }
    // console.log('!!!', username)
    // 不存在，发送请求获取用户信息
    run()
  }, [username])
  return { waitingUserDate }
}

export default useLoadUserData
