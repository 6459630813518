import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import styles from './common.module.scss'
import { useDebounceFn, useRequest } from 'ahooks'
import { Typography, Empty, Spin, Space, Switch, ConfigProvider } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { useSearchParams } from 'react-router-dom'
import QuestionCard from '../../components/QuestionCard/QuestionCard'
import QuestionNewCard from '../../components/QuestionCard/QuestionNewCard'
import ListSearch from '../../components/Search/ListSearch'
import { getQuestionListService } from '../../services/question'

import { LIST_SEARCH_PARAM_KEY, LIST_PAGE_SIZE } from '../../constant'
import useGetUserInfo from '../../hooks/useGetUserInfo'

const { Title } = Typography

// 问卷列表数据
// const rawQuestionList = [
//   {
//     _id: 'q1', // mongodb 数据库 _id
//     title: '问卷1',
//     isPublished: false,
//     isStar: false,
//     answerCount: 5,
//     createdAt: '3月10日 10:30',
//   }
// ]

const List: FC = () => {
  // url 参数，虽然没有page pageSize,但是有 keyword
  const [searchParams] = useSearchParams()
  // 当前页
  const [page, setPage] = useState(1)
  const pageOne = useRef(1)
  // 问卷数据列表
  const [list, setList] = useState([])
  // 总数量
  const [total, setTotal] = useState(0)
  // 搜索关键字
  const keyword = searchParams.get(LIST_SEARCH_PARAM_KEY) || ''
  // 是否已经开始加载（防抖，有延迟时间）
  const [started, setStarted] = useState(false)
  // 切换
  const [toggle, setToggle] = useState(true)

  // 获取"下拉加载更多"的DOM
  const containerRef = useRef<HTMLDivElement>(null)
  // 获取问卷列表盒子DOM
  const listBox = useRef<HTMLDivElement>(null)

  // 触发加载更多
  const { run: tryLoadMore } = useDebounceFn(
    () => {
      // 获取到“下拉加载更多”DOM
      const elem = containerRef.current || null
      // 获取元素的位置
      const domRect = elem!.getBoundingClientRect()
      // 拿到'上拉加载更多'元素的底部 距离 列表盒子顶部的距离
      const { bottom } = domRect
      // 列表盒子的可视区高度
      const listBoxHeight = (listBox.current as HTMLDivElement).clientHeight
      console.log(domRect, top, bottom, listBoxHeight)
      // 滚动到底部时 bottom是780 ,listBoxHeight 是固定的563
      // 使用可以算出差值 = 780 - 552 = 218
      if (bottom - 230 <= listBoxHeight) {
        setStarted(true)
        load() // 触发上拉加载数据
      }
    },
    {
      wait: 300,
    }
  )

  // 利用ahooks的useRequest发送请求获取问卷列表
  const { user_id } = useGetUserInfo()
  console.log('收到发生地', user_id)
  const { run: load, loading } = useRequest(
    async () => {
      // 请求接口
      const data = await getQuestionListService({
        user_id,
        keyword,
        page: pageOne.current,
        pagesize: LIST_PAGE_SIZE,
      })
      return data
    },
    {
      manual: true,
      onSuccess(result) {
        const { questionList = [], total = 0 } = result
        setList(list.concat(questionList)) // 累计数据
        setTotal(total) // 总数量
        setPage(page + 1) // 页面
      },
    }
  )

  //  时机2：当页面滚动时，尝试触发加载
  useEffect(() => {
    // 判断上拉加载完毕
    if (total > list.length) {
      // 绑定滚动事件
      listBox.current && listBox.current.addEventListener('scroll', tryLoadMore)
    }
    // 移除事件监听
    return () => {
      // 解绑滚动事件
      listBox.current && listBox.current.removeEventListener('scroll', tryLoadMore)
    }
  }, [page]) // 初始化 或 页码改变，触发

  // 时机1：当初始页面加载 或 url参数【keyword】变化时，触发加载
  useEffect(() => {
    setStarted(false)
    setPage(1)
    pageOne.current = 1
    setList([])
    setTotal(0)
    load()
  }, [keyword, toggle, user_id])

  useEffect(() => {
    setStarted(false)
    pageOne.current = page
  }, [page])

  const LoadMoreContentEle = useMemo(() => {
    if (loading) return <Spin />
    if (total === 0) return <Empty description="暂无数据" />
    if (!(total > list.length)) return <span>到底啦！</span>
    return <span>上拉加载更多</span>
  }, [loading, total])

  // 切换显示
  const toggleReveal = (checked: boolean) => {
    setToggle(checked)
  }

  // 删除问卷后，刷新列表
  function handleRefresh() {
    setList([])
    setPage(1)
    pageOne.current = 1
    load()
  }

  return (
    <>
      {/* 头部 */}
      <div className={styles.header}>
        <div className={styles.left}>
          <Title level={3}>
            我的问卷 &nbsp;
            <Space direction="vertical">
              <ConfigProvider
                theme={{
                  components: {
                    Switch: {
                      /* 这里是你的组件 token */
                      handleSizeSM: 10,
                    },
                  },
                }}
              >
                <Switch
                  checkedChildren="列表显示"
                  unCheckedChildren="卡片显示"
                  checked={toggle}
                  onChange={toggleReveal}
                  style={{ background: 'rgb(22 119 255)' }}
                />
              </ConfigProvider>
            </Space>
          </Title>
          page:{page} &nbsp; pageOne:{pageOne.current}
        </div>
        <div className={styles.right}>
          {/* 搜索 */}
          <ListSearch />
        </div>
      </div>

      {/* 内容 */}
      <div className={styles.content_list}>
        {/* 问卷列表 */}
        <div className={toggle ? styles.list_scroll : styles.list_scroll_card} ref={listBox}>
          {toggle
            ? list.length > 0 &&
              list
                .filter((c: any) => !c.isDeleted)
                .map((q: any) => {
                  const { fe_id } = q
                  return <QuestionCard key={fe_id} {...q} refresh={handleRefresh} />
                })
            : list.length > 0 &&
              list
                .filter((c: any) => !c.isDeleted)
                .map((q: any) => {
                  const { fe_id } = q
                  return <QuestionNewCard key={fe_id} {...q} />
                })}

          {/* 底部 */}
          <div className={styles.footer} ref={containerRef}>
            <div ref={containerRef}>{LoadMoreContentEle}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default List
