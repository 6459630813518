import React, { FC, useEffect, useState } from 'react'
import { Pagination } from 'antd'
// 常量
import { LIST_PAGE_PARAM_KEY, LIST_PAGE_SIZE_PARAM_KEY, LIST_PAGE_SIZE } from '../../constant'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

type PropType = {
  total: number
}

const ListPage: FC<PropType> = (props: PropType) => {
  const { total } = props
  // 当前页
  const [current, setCurrent] = useState(1)
  // 每页长度
  const [pagesize, setpagesize] = useState(LIST_PAGE_SIZE)

  // 从 url 参数中找到 page pagesize，并且同步到 Pagination 组件中
  const [searchParams] = useSearchParams()
  // 当 page pagesize 改变时， 跳转页面（改变 url 参数）
  const navigate = useNavigate()
  // 获取路径
  const { pathname } = useLocation()

  // 当依赖项 searchParams 改变时，触发
  useEffect(() => {
    // 当前页
    const page = parseInt(searchParams.get(LIST_PAGE_PARAM_KEY) || '') || 1
    // 每页长度
    const pagesize = parseInt(searchParams.get(LIST_PAGE_SIZE_PARAM_KEY) || '') || LIST_PAGE_SIZE
    setCurrent(page)
    setpagesize(pagesize)
  }, [searchParams])

  function handlePageChange(page: number, pagesize: number) {
    // 修改为新的page pagesize
    searchParams.set(LIST_PAGE_PARAM_KEY, page.toString())
    searchParams.set(LIST_PAGE_SIZE_PARAM_KEY, pagesize.toString())
    // 跳转
    navigate({
      pathname,
      search: searchParams.toString(), //除了改变 page pagesize 之外，其他的url参数要带着
    })
  }

  return (
    <div>
      <Pagination
        align="center"
        current={current}
        pageSize={pagesize}
        total={total}
        onChange={handlePageChange}
      />
    </div>
  )
}

export default ListPage
