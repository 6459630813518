import React, { useRef, useState } from 'react'
import './App.css'
import { RouterProvider } from 'react-router-dom'
import router from './router'

function App() {
  return <RouterProvider router={router}></RouterProvider>
}

export default App
