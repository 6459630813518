/**
 * @deprecated 方便获取redux中user用户信息数据
 * @author lkk
 */
import { useSelector } from 'react-redux'
// 导入模块类型
import { StateType } from '../store'
// 导入用户信息类型
import { UserStateType } from '../store/userReducer'

function useGetUserInfo() {
  // 获取store中user数据
  const { username, nickname, token, user_id } = useSelector<StateType>(
    state => state.user
  ) as UserStateType
  return { username, nickname, token, user_id }
}

export default useGetUserInfo
