import React, { FC, useState } from 'react'
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons'
import { Avatar, Card, Flex, Switch } from 'antd'

const { Meta } = Card

const actions: React.ReactNode[] = [
  <EditOutlined key="edit" />,
  <SettingOutlined key="setting" />,
  <EllipsisOutlined key="ellipsis" />,
]

// props类型
type PropsType = {
  _id: string
  fe_id: string
  title: string
  isPublished: boolean
  isStar: boolean
  answerCount: number
  createAt: string
}

const QuestionNewCard: FC<PropsType> = (props: PropsType) => {
  const { _id, fe_id, title, isPublished, isStar, answerCount, createAt } = props

  return (
    <Flex gap="middle" align="start" vertical>
      <Card
        style={{ width: 300, margin: '15px' }}
        cover={
          <img
            alt="example"
            src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
          />
        }
        actions={[
          <SettingOutlined key="setting" />,
          <EditOutlined key="edit" />,
          <EllipsisOutlined key="ellipsis" />,
        ]}
      >
        <Meta
          avatar={<Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=8" />}
          title={title}
          description="This is the description"
        />
      </Card>
    </Flex>
  )
}

export default QuestionNewCard
